body.login {
    background-color: $background-color;
    color: $main-color;
    a {
        color: $main-color; }
    #login_error {
        border: 0px solid transparent;
        border: 3px solid #dc3232;
        color: #dc3232; }
    .message {
        border: 0px solid transparent;
        border: 3px solid green;
        color: green; }
    #login {
        padding: 0;
        max-width: 90vw;
        label {
            font-family: $main-font-family;
            line-height: $main-line-height; } }
    form {
        border: 3px solid $main-color;
        padding-bottom: 25px;
        input {
            border: 3px solid $main-color !important;
            border-radius: 0 0 0 0;
            outline: none;
            -webkit-appearance: none;
            background-color: $form-bg;
            box-shadow: 0 0 0 0 transparent;
            transition: all 0.2s ease-in-out;
            &:focus, &:active, &:visited {
                outline: none !important;
                box-shadow: 0 0 0 2px $main-color !important;
 } }                //border: 3px solid $main-color !important
        #rememberme {
            min-height: auto;
            width: 22px;
            height: 22px;
            display: inline-block;
            &:before {
                //margin: -0.187rem 0 0 -.4rem
                width: 20px;
                height: 20px; } }
        .dashicons {
            color: $main-color; }
        .user-pass-wrap {
            margin-bottom: 5px;
            @include breakpoint(medium) {
                margin-bottom: 20px; } }
        #wp-submit {
            color: $main-color;
            background-color: transparent;
            font-size: 14px;
            border-radius: 0 0 0 0;
            width: 100%;
            margin-top: 20px;
            &:focus, &:active, &:visited, &:hover {
                background-color: transparent;
                outline: none !important;
                border: 0px solid transparent;
                box-shadow: 0 0 0 2px $main-color !important; }
            &:hover {}
            @include breakpoint(medium) {
                margin-top: 0px;
                width: auto; } }

        .button-secondary.wp-hide-pw {
            padding: 0px;
            height: 38px;
            width: 38px;
            min-height: 38px;
            min-width: 38px;
            margin: 3px;
            &:focus, &:active, &:visited {
                border-radius: 0 0 0 0;
                border: 0px solid transparent;
                box-shadow: 0 0 0 1px $main-color !important; }
            &:hover {
                border: 0px solid transparent;
                box-shadow: 0 0 0 2px transparent !important; } } }

    #nav, #backtoblog {
        a {
            color: $main-color;
            &:focus, &:active, &:visited, &:hover {
                color: $main-color;
                box-shadow: none;
                font-weight: bold; } } }
    h1 a {
        background: url('../img/glunz/GLUNZ_logo.svg');
        width: 150px;
        height: 67px;
        margin: 0 auto 10vh auto;
        @include breakpoint(medium) {
            width: 200px;
            height: 90px;
            margin: 0 auto 20vh auto; } }

    #nav, #backtoblog {
        text-align: center;
        margin-top: 10px;
        @include breakpoint(medium) {
            margin-top: 20px; } }

    .privacy-policy-page-link {
        margin: auto;
        position: absolute;
        display: block;
        bottom: 5px;
        left: 0px;
        text-align: center;
        @include breakpoint(medium) {
            bottom: 20px; }
        .privacy-policy-link {
            display: block;
            position: relative;
            &:focus, &:active, &:visited, &:hover {
                color: $main-color;
                box-shadow: none;
                font-weight: bold; } } }

    #language-switcher {
        //margin-top: 10vh
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        select, input {
            border: 1px solid $main-color !important;
            border-radius: 0px;
            color: $main-color; } } }

.interim-login.login h1 a {
    width: 150px;
    margin-bottom: 10vh; }
