html {
    height: 100%;
    font-size: $base-font-size;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    overflow-y: scroll;


    &:focus {
        outline: 0; } }

body {
    font-family: $main-font-family;
    line-height: $main-line-height;
    font-weight: $base-font-weight;
    color: $primary-color;
    font-size: 1.6rem; // Cheat typo — 1px = 1rem || focus on 'rem' units
    text-rendering: optimizeLegibility !important; // Add more lisibility on fonts
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased !important; // Smoothing fonts
    hyphens: auto;

    @include breakpoint(medium) {
        hyphens: none; } }


p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
    margin: 0;
    padding: 0; }

.bagnard {
    font-family: $second-font-family; }

.lead {
    font-size: 2.2rem;
    line-height: 3.2rem; }


a:not(.header-logo) {
    text-decoration: none;
    transition: opacity .2s ease;
    opacity: 1;
    color: inherit;

    //&:not(.button)
    //    &:hover
 }    //        opacity: 0.7

.underline-hover, .text-block-wrapper a, #category-list a, #footer-container h5 a {
    text-decoration: none;
    display: inline-block;
    border-bottom: 2px solid $primary-color;
    &:after {
        content: "";
        background-color: $secondary-color;
        display: block;
        position: relative;
        height: 2px;
        width: 0px;
        transition: width .5s;
        bottom: -2px; }
    &:hover:after {
        width: 100%; } }


p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-weight: normal;
    margin-bottom: 1rem; }

blockquote {
    margin: 4rem 0;
    padding: 2rem 2rem;
    border: 1px solid $secondary-color;
    p {
        margin: 0px; }
    @include breakpoint(large) {
        padding: 3rem 4rem; } }

.rubrik,
.smalltext {
   font-size: 1.6rem;
   line-height: 2rem;
   font-family: $main-font-family; }

.gt {
   font-size: 1.8rem;
   line-height: 2.8rem;
   font-weight: normal;
   font-family: $main-font-family; }


ul, ol {
    margin: 0;
    padding: 0;
    list-style: none; }

h1 {
    font-size: 4.5rem;
    line-height: 1.2em;
    font-weight: $regular;
    margin-bottom: 2rem; }
h2 {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: $regular;
    margin-bottom: 4.4rem; }
h3 {
    font-size: 3.8rem;
    line-height: 3.8rem;
    font-weight: $regular;
    margin: 4rem 0 2.8rem 0; }
h4 {
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: $regular;
    padding: 1.4rem 0;
    margin: 0; }
h5 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: $bold;
    padding: 1.2rem 0;
    margin: 0; }

h6 {
    font-size: 1.8rem;
    line-height: 2.4rem; }

@include breakpoint(medium) {
    h1 {
        font-size: 8rem;
        line-height: 12rem;
        text-align: center;
        margin-bottom: 4rem; }

    h2 {
        font-size: 5.4rem;
        line-height: 6.6rem;
        font-weight: $regular;
        margin-bottom: 4.4rem; }
    h3 {
        font-size: 6rem;
        line-height: 6rem;
        margin: 6rem 0 2.8rem 0; }
    h4 {
        font-size: 4rem;
        line-height: 1;
        padding: 1.8rem 0;
        margin: 0; }
    h5 {
        font-size: 3rem;
        line-height: 3.6rem;
        padding: 1.4rem 0;
        margin: 0; }
    h6 {
        font-size: 2.4rem;
        line-height: 3rem;
        font-weight: $regular; } }

@include breakpoint(large) {
    h1 {
        font-size: 10rem;
        line-height: 12rem;
        text-align: center; }
    h3 {
        margin: 4rem 0 2.8rem 0; } }

.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.04em; }


.table {
    width: 100%;
    border-top: 2px solid $secondary-color;
    font-size: 1.4rem;
    @include breakpoint(large) {
        font-size: 1.6rem; }


    tr {
        border-bottom: 2px solid $secondary-color; }


    td {
        padding: 1rem;

        &:first-child {
            padding-left: 0; }

        &:last-child {
            padding-right: 0; } } }


input,
.fake-input {
    min-height: 4.2rem;
    color: inherit;
    background-color: transparent;
    padding: 3px 0 4px 0;
    outline: 0;
    margin-bottom: .5rem;
    border-width: 0 0 0 0;
    border-style: solid;
    border-color: inherit;
    border-radius: 0;
    position: relative;

    &:focus, &:active {
        //box-shadow: 0 0 5px $medium-gray

        &::placeholder {
                opacity: .6; } }

    &::placeholder {
        opacity: 1; }

    &:invalid {
        box-shadow: none; }

    // Remove Spinner on number fields
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    &[type=number] {
        display: none;
        -moz-appearance: textfield; }

    &[type="button"],
    &[type="submit"] {
        padding: .3em .7em;
        transition: background-color .15s ease; }

    &[type=text],
    &[type=date],
    &[type=email],
    &[type=number] {
        border-width: 0 0 2px 0;
        border-color: inherit; } }

input.buchung-abschliessen {
    padding: .5em .9em;
    border-color: $secondary-color;
    //background-color: $secondary-color
    color: $secondary-color;
    &:hover {
        background-color: $secondary-color;
        color: $primary-color; } }


[role=button] {
    cursor: pointer;
    display: inline; }


.fake-input {
    cursor: pointer; }

.fancy-number-input {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline; }

    .button {
        font-size: 2.3rem;
        padding: 0px 12px; }

    .number {
        width: 4rem;
        text-align: center;
        display: inline-block;
        font-size: 2.8rem; } }

select {
    border-width: 0 0 2px 0;
    border-color: inherit; }

.vs__selected {
    margin-left: 0;
    padding-left: 0; }

#date-select {
    &:after {
        content: "";
        background-image: url('../img/drop_arrow.svg');
        width: 14px;
        height: 10px;
        display: block;
        position: absolute;
        right: 0;
        top: 40%;
        transition: transform .15s cubic-bezier(1,-.115,.975,.855); }

    &.fake-input.active {
        &:after {
            transform: rotate(180deg); } } }


.custom-select {
    .vs__dropdown-toggle {
        border-width: 0 0 2px 0;
        border-radius: 0;
        border-color: inherit; }

    .vs__dropdown-menu {
        background-color: $primary-color;
        border: none;
        box-shadow: none;
        border-radius: 0;

        .vs__dropdown-option {
            color: $secondary-color;
            &.vs__dropdown-option--highlight {
                background-color: $yellow;
                color: $primary-color; } } }

    .vs__selected-options {
        padding: 0; }
    .vs__selected {
        padding-left: 0;
        color: $primary-color; }
    .vs__open-indicator {
        fill: $primary-color; } }


label {
    font-weight: $bold; }

.bottom-label {
    font-size: 2rem; }

.fancy-label {
    position: relative;
    display: block;
    height: 5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;

    .top {
        position: absolute;
        width: 100%;
        display: block;
        transition: top .2s ease, font-size .2s ease;
        top: 1em;
        cursor: text;
        //color: inherit
        //color: $primary-color

        &.focussed {
            top: 0;
            font-size: 70%;
 } }            //color: black
    input, textarea {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
        color: $black;
        text-align: left;
        text-align: left !important;
        &:-webkit-autofill {
            box-shadow: 0 0 0 30px lighten($blue, 15%) inset !important;
            min-height: auto;
            -webkit-text-fill-color: $black !important; } }

    &.textfield {
        margin-top: 2rem;
        height: 10rem;
        .top {
 }            //left: 1.5rem
        textarea {
            border-width: 0 0 2px 0;
            padding-left: 0;
 } } }            //padding-top: 2.5rem

.warning {
    font-size: 1.5rem;
    color: $warning-color !important; }

textarea {
    border: 2px solid $primary-color;
    background: transparent;
    width: 100%;
    max-width: 100%;
    margin: 0 0 2rem 0;
    padding: 1rem;
    border-radius: 0; }


.center {
    text-align: center; }

.right {
    text-align: right; }

button,
.button, {
    border: 2px solid $primary-color;
    border-radius: 1.4em;
    background-color: transparent;
    color: $primary-color;
    padding: .5em 1.6em;
    cursor: pointer;
    user-select: none;
    font-family: $main-font-family;
    font-weight: $bold;
    font-size: 1.8rem;
    transition: all .2s ease;
    display: inline-block;
    @extend .uppercase;

    &.big {
        padding: .8em 1.5em .6em;
        font-size: 2rem; }

    &.active {}
    &:hover {
        background-color: $primary-color;
        color: $secondary-color; }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed; } }
.simple-button {
    font-family: $main-font-family;
    border-bottom: 2px solid $primary-color;
    display: inline-block;
    @extend .uppercase; }

hr {
    margin-bottom: 1em;
    border-bottom: 0;
    border-color: $light-gray;
    border-style: solid; }


label.fancy-checkbox {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: normal;

    &:hover .checkmark:not(.checked):before {
        display: block;
        opacity: .5; }

    .checkmark {
        position: relative;
        display: inline-block;
        top: .2em;
        left: 0;
        height: 20px;
        width: 20px;
        min-width: 20px;
        border: 2px solid $primary-color;
        margin-right: .5em;

        &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: $primary-color;
            top: 2px;
            left: 2px;
            opacity: 0;
            transition: opacity .15s ease; }

        &.checked {
            &:before {
                opacity: 1; } } }


    input {
        display: none; } }



.entry-content {
    ul {
        margin: 0 0 1em 1em;
        list-style: disc; }

    ol {
        margin: 0 0 1em 1em;
        list-style: number; } }
