/////////////////////////
//  Path
/////////////////////////
$font-path              : '../fonts/';
$img-path               : '../img/';
//$video-path           : '../video/'
//$sound-path           : '../sound/'



/////////////////////////
//  Typography
/////////////////////////
$base-font-size         : 62.5%; // Cheat typo — Explenation 05_typography.sass
$main-line-height       : 1.5;
$main-font-family       : 'gopher', Arial, Helvetica, sans-serif;
$second-font-family     : 'bagnard', Times New Roman, Times, Baskerville, Georgia, serif;
$regular                : 400;
$bold                   : 700;
$base-font-weight       : $regular;



/////////////////////////
//   Grid sttings & Breakpoints
/////////////////////////
$site-width             : 1360px;
$breakpoints            : ( small: (0px), medium: (640px), large: (1024px), xlarge: (1360px) );

$grid-columns           : 16;
$column-gap             : 0px;
$column-small-gap       : 0px;


/////////////////////////
//   Transiton
/////////////////////////
$easy-In                : cubic-bezier(.59,.35,.22,1);
$easy-Out               : cubic-bezier(.99,.01,.58,1);


/////////////////////////
//   Colors
/////////////////////////
$white                   : #ffffff;
$gray                    : #eee;
$black                   : #000000;


$light-gray: darken($white, 10%);
$medium-gray: darken($white, 25%);
$dark-gray: darken($white, 50%);
$text-gray:  darken($white, 90%);

$primary-color           : #003f2b;
$secondary-color         : #8cf29a;
$warning-color           : #ff392e;
$yellow                  : #fdffc5;
$blue                    : #93d1ed;

/////////////////////////
//   Login Colors
/////////////////////////
$background-color        : #FFFFFF;
$main-color              : #0000FF;
$form-bg                 : #FFFFFF;
