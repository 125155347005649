/////////////////////////
//  Import Fonts
/////////////////////////

// Gopher
@include font-face('gopher', #{$font-path}GopherText-Medium, regular, normal, ttf);
@include font-face('gopher', #{$font-path}GopherText-Bold, bold, normal, ttf);

// Bagnard
@include font-face('bagnard', #{$font-path}Bagnard, regular, normal, otf);
