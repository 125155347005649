@font-face {
  font-family: "gopher";
  font-style: normal;
  font-weight: regular;
  src: url("../fonts/GopherText-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "gopher";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/GopherText-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "bagnard";
  font-style: normal;
  font-weight: regular;
  src: url("../fonts/Bagnard.otf") format("opentype"); }

html {
  height: 100%;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow-y: scroll; }
  html:focus {
    outline: 0; }

body {
  font-family: "gopher", Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #003f2b;
  font-size: 1.6rem;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  hyphens: auto; }
  @media only screen and (min-width: 640px) {
    body {
      hyphens: none; } }

p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
  margin: 0;
  padding: 0; }

.bagnard {
  font-family: "bagnard", Times New Roman, Times, Baskerville, Georgia, serif; }

.lead {
  font-size: 2.2rem;
  line-height: 3.2rem; }

a:not(.header-logo) {
  text-decoration: none;
  transition: opacity .2s ease;
  opacity: 1;
  color: inherit; }

.underline-hover, .text-block-wrapper a, #category-list a, #footer-container h5 a {
  text-decoration: none;
  display: inline-block;
  border-bottom: 2px solid #003f2b; }
  .underline-hover:after, .text-block-wrapper a:after, #category-list a:after, #footer-container h5 a:after {
    content: "";
    background-color: #8cf29a;
    display: block;
    position: relative;
    height: 2px;
    width: 0px;
    transition: width .5s;
    bottom: -2px; }
  .underline-hover:hover:after, .text-block-wrapper a:hover:after, #category-list a:hover:after, #footer-container h5 a:hover:after {
    width: 100%; }

p {
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: normal;
  margin-bottom: 1rem; }

blockquote {
  margin: 4rem 0;
  padding: 2rem 2rem;
  border: 1px solid #8cf29a; }
  blockquote p {
    margin: 0px; }
  @media only screen and (min-width: 1024px) {
    blockquote {
      padding: 3rem 4rem; } }

.rubrik,
.smalltext {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "gopher", Arial, Helvetica, sans-serif; }

.gt {
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: normal;
  font-family: "gopher", Arial, Helvetica, sans-serif; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

h1 {
  font-size: 4.5rem;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 2rem; }

h2 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 400;
  margin-bottom: 4.4rem; }

h3 {
  font-size: 3.8rem;
  line-height: 3.8rem;
  font-weight: 400;
  margin: 4rem 0 2.8rem 0; }

h4 {
  font-size: 3rem;
  line-height: 3.8rem;
  font-weight: 400;
  padding: 1.4rem 0;
  margin: 0; }

h5 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  padding: 1.2rem 0;
  margin: 0; }

h6 {
  font-size: 1.8rem;
  line-height: 2.4rem; }

@media only screen and (min-width: 640px) {
  h1 {
    font-size: 8rem;
    line-height: 12rem;
    text-align: center;
    margin-bottom: 4rem; }
  h2 {
    font-size: 5.4rem;
    line-height: 6.6rem;
    font-weight: 400;
    margin-bottom: 4.4rem; }
  h3 {
    font-size: 6rem;
    line-height: 6rem;
    margin: 6rem 0 2.8rem 0; }
  h4 {
    font-size: 4rem;
    line-height: 1;
    padding: 1.8rem 0;
    margin: 0; }
  h5 {
    font-size: 3rem;
    line-height: 3.6rem;
    padding: 1.4rem 0;
    margin: 0; }
  h6 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 400; } }

@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 10rem;
    line-height: 12rem;
    text-align: center; }
  h3 {
    margin: 4rem 0 2.8rem 0; } }

.uppercase, button,
.button, .simple-button {
  text-transform: uppercase;
  letter-spacing: 0.04em; }

.table {
  width: 100%;
  border-top: 2px solid #8cf29a;
  font-size: 1.4rem; }
  @media only screen and (min-width: 1024px) {
    .table {
      font-size: 1.6rem; } }
  .table tr {
    border-bottom: 2px solid #8cf29a; }
  .table td {
    padding: 1rem; }
    .table td:first-child {
      padding-left: 0; }
    .table td:last-child {
      padding-right: 0; }

input,
.fake-input {
  min-height: 4.2rem;
  color: inherit;
  background-color: transparent;
  padding: 3px 0 4px 0;
  outline: 0;
  margin-bottom: .5rem;
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: inherit;
  border-radius: 0;
  position: relative; }
  input:focus::placeholder, input:active::placeholder,
  .fake-input:focus::placeholder,
  .fake-input:active::placeholder {
    opacity: .6; }
  input::placeholder,
  .fake-input::placeholder {
    opacity: 1; }
  input:invalid,
  .fake-input:invalid {
    box-shadow: none; }
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button,
  .fake-input::-webkit-outer-spin-button,
  .fake-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input[type=number],
  .fake-input[type=number] {
    display: none;
    -moz-appearance: textfield; }
  input[type="button"], input[type="submit"],
  .fake-input[type="button"],
  .fake-input[type="submit"] {
    padding: .3em .7em;
    transition: background-color .15s ease; }
  input[type=text], input[type=date], input[type=email], input[type=number],
  .fake-input[type=text],
  .fake-input[type=date],
  .fake-input[type=email],
  .fake-input[type=number] {
    border-width: 0 0 2px 0;
    border-color: inherit; }

input.buchung-abschliessen {
  padding: .5em .9em;
  border-color: #8cf29a;
  color: #8cf29a; }
  input.buchung-abschliessen:hover {
    background-color: #8cf29a;
    color: #003f2b; }

[role=button] {
  cursor: pointer;
  display: inline; }

.fake-input {
  cursor: pointer; }

.fancy-number-input {
  font-weight: normal;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 640px) {
    .fancy-number-input {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline; } }
  .fancy-number-input .button {
    font-size: 2.3rem;
    padding: 0px 12px; }
  .fancy-number-input .number {
    width: 4rem;
    text-align: center;
    display: inline-block;
    font-size: 2.8rem; }

select {
  border-width: 0 0 2px 0;
  border-color: inherit; }

.vs__selected {
  margin-left: 0;
  padding-left: 0; }

#date-select:after {
  content: "";
  background-image: url("../img/drop_arrow.svg");
  width: 14px;
  height: 10px;
  display: block;
  position: absolute;
  right: 0;
  top: 40%;
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855); }

#date-select.fake-input.active:after {
  transform: rotate(180deg); }

.custom-select .vs__dropdown-toggle {
  border-width: 0 0 2px 0;
  border-radius: 0;
  border-color: inherit; }

.custom-select .vs__dropdown-menu {
  background-color: #003f2b;
  border: none;
  box-shadow: none;
  border-radius: 0; }
  .custom-select .vs__dropdown-menu .vs__dropdown-option {
    color: #8cf29a; }
    .custom-select .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
      background-color: #fdffc5;
      color: #003f2b; }

.custom-select .vs__selected-options {
  padding: 0; }

.custom-select .vs__selected {
  padding-left: 0;
  color: #003f2b; }

.custom-select .vs__open-indicator {
  fill: #003f2b; }

label {
  font-weight: 700; }

.bottom-label {
  font-size: 2rem; }

.fancy-label {
  position: relative;
  display: block;
  height: 5rem;
  margin-bottom: 1rem;
  font-size: 1.8rem; }
  .fancy-label .top {
    position: absolute;
    width: 100%;
    display: block;
    transition: top .2s ease, font-size .2s ease;
    top: 1em;
    cursor: text; }
    .fancy-label .top.focussed {
      top: 0;
      font-size: 70%; }
  .fancy-label input, .fancy-label textarea {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #000000;
    text-align: left;
    text-align: left !important; }
    .fancy-label input:-webkit-autofill, .fancy-label textarea:-webkit-autofill {
      box-shadow: 0 0 0 30px #d5edf8 inset !important;
      min-height: auto;
      -webkit-text-fill-color: #000000 !important; }
  .fancy-label.textfield {
    margin-top: 2rem;
    height: 10rem; }
    .fancy-label.textfield textarea {
      border-width: 0 0 2px 0;
      padding-left: 0; }

.warning {
  font-size: 1.5rem;
  color: #ff392e !important; }

textarea {
  border: 2px solid #003f2b;
  background: transparent;
  width: 100%;
  max-width: 100%;
  margin: 0 0 2rem 0;
  padding: 1rem;
  border-radius: 0; }

.center {
  text-align: center; }

.right {
  text-align: right; }

button,
.button {
  border: 2px solid #003f2b;
  border-radius: 1.4em;
  background-color: transparent;
  color: #003f2b;
  padding: .5em 1.6em;
  cursor: pointer;
  user-select: none;
  font-family: "gopher", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  transition: all .2s ease;
  display: inline-block; }
  button.big,
  .button.big {
    padding: .8em 1.5em .6em;
    font-size: 2rem; }
  button:hover,
  .button:hover {
    background-color: #003f2b;
    color: #8cf29a; }
  button[disabled],
  .button[disabled] {
    opacity: .5;
    cursor: not-allowed; }

.simple-button {
  font-family: "gopher", Arial, Helvetica, sans-serif;
  border-bottom: 2px solid #003f2b;
  display: inline-block; }

hr {
  margin-bottom: 1em;
  border-bottom: 0;
  border-color: #e6e6e6;
  border-style: solid; }

label.fancy-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: normal; }
  label.fancy-checkbox:hover .checkmark:not(.checked):before {
    display: block;
    opacity: .5; }
  label.fancy-checkbox .checkmark {
    position: relative;
    display: inline-block;
    top: .2em;
    left: 0;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border: 2px solid #003f2b;
    margin-right: .5em; }
    label.fancy-checkbox .checkmark:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #003f2b;
      top: 2px;
      left: 2px;
      opacity: 0;
      transition: opacity .15s ease; }
    label.fancy-checkbox .checkmark.checked:before {
      opacity: 1; }
  label.fancy-checkbox input {
    display: none; }

.entry-content ul {
  margin: 0 0 1em 1em;
  list-style: disc; }

.entry-content ol {
  margin: 0 0 1em 1em;
  list-style: number; }

body.login {
  background-color: #FFFFFF;
  color: #0000FF; }
  body.login a {
    color: #0000FF; }
  body.login #login_error {
    border: 0px solid transparent;
    border: 3px solid #dc3232;
    color: #dc3232; }
  body.login .message {
    border: 0px solid transparent;
    border: 3px solid green;
    color: green; }
  body.login #login {
    padding: 0;
    max-width: 90vw; }
    body.login #login label {
      font-family: "gopher", Arial, Helvetica, sans-serif;
      line-height: 1.5; }
  body.login form {
    border: 3px solid #0000FF;
    padding-bottom: 25px; }
    body.login form input {
      border: 3px solid #0000FF !important;
      border-radius: 0 0 0 0;
      outline: none;
      -webkit-appearance: none;
      background-color: #FFFFFF;
      box-shadow: 0 0 0 0 transparent;
      transition: all 0.2s ease-in-out; }
      body.login form input:focus, body.login form input:active, body.login form input:visited {
        outline: none !important;
        box-shadow: 0 0 0 2px #0000FF !important; }
    body.login form #rememberme {
      min-height: auto;
      width: 22px;
      height: 22px;
      display: inline-block; }
      body.login form #rememberme:before {
        width: 20px;
        height: 20px; }
    body.login form .dashicons {
      color: #0000FF; }
    body.login form .user-pass-wrap {
      margin-bottom: 5px; }
      @media only screen and (min-width: 640px) {
        body.login form .user-pass-wrap {
          margin-bottom: 20px; } }
    body.login form #wp-submit {
      color: #0000FF;
      background-color: transparent;
      font-size: 14px;
      border-radius: 0 0 0 0;
      width: 100%;
      margin-top: 20px; }
      body.login form #wp-submit:focus, body.login form #wp-submit:active, body.login form #wp-submit:visited, body.login form #wp-submit:hover {
        background-color: transparent;
        outline: none !important;
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px #0000FF !important; }
      @media only screen and (min-width: 640px) {
        body.login form #wp-submit {
          margin-top: 0px;
          width: auto; } }
    body.login form .button-secondary.wp-hide-pw {
      padding: 0px;
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      margin: 3px; }
      body.login form .button-secondary.wp-hide-pw:focus, body.login form .button-secondary.wp-hide-pw:active, body.login form .button-secondary.wp-hide-pw:visited {
        border-radius: 0 0 0 0;
        border: 0px solid transparent;
        box-shadow: 0 0 0 1px #0000FF !important; }
      body.login form .button-secondary.wp-hide-pw:hover {
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px transparent !important; }
  body.login #nav a, body.login #backtoblog a {
    color: #0000FF; }
    body.login #nav a:focus, body.login #nav a:active, body.login #nav a:visited, body.login #nav a:hover, body.login #backtoblog a:focus, body.login #backtoblog a:active, body.login #backtoblog a:visited, body.login #backtoblog a:hover {
      color: #0000FF;
      box-shadow: none;
      font-weight: bold; }
  body.login h1 a {
    background: url("../img/glunz/GLUNZ_logo.svg");
    width: 150px;
    height: 67px;
    margin: 0 auto 10vh auto; }
    @media only screen and (min-width: 640px) {
      body.login h1 a {
        width: 200px;
        height: 90px;
        margin: 0 auto 20vh auto; } }
  body.login #nav, body.login #backtoblog {
    text-align: center;
    margin-top: 10px; }
    @media only screen and (min-width: 640px) {
      body.login #nav, body.login #backtoblog {
        margin-top: 20px; } }
  body.login .privacy-policy-page-link {
    margin: auto;
    position: absolute;
    display: block;
    bottom: 5px;
    left: 0px;
    text-align: center; }
    @media only screen and (min-width: 640px) {
      body.login .privacy-policy-page-link {
        bottom: 20px; } }
    body.login .privacy-policy-page-link .privacy-policy-link {
      display: block;
      position: relative; }
      body.login .privacy-policy-page-link .privacy-policy-link:focus, body.login .privacy-policy-page-link .privacy-policy-link:active, body.login .privacy-policy-page-link .privacy-policy-link:visited, body.login .privacy-policy-page-link .privacy-policy-link:hover {
        color: #0000FF;
        box-shadow: none;
        font-weight: bold; }
  body.login #language-switcher {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center; }
    body.login #language-switcher select, body.login #language-switcher input {
      border: 1px solid #0000FF !important;
      border-radius: 0px;
      color: #0000FF; }

.interim-login.login h1 a {
  width: 150px;
  margin-bottom: 10vh; }
